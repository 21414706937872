import { createRouter, createWebHistory } from "vue-router";
import { getToken } from "@/services";
import authRoutes from "@/modules/auth/routes";
import teacherRoutes from "@/modules/teachers/routes";
import coordinationRoutes from "@/modules/coordination/routes";
import reportsRoutes from "@/modules/reports/routes";
import notificationsRoutes from "@/modules/notifications/routes";
import settingsRoutes from "@/modules/settings/routes";
import botRoutes from "@/modules/bot/routes";
import schoolRoutes from "@/modules/school/routes";
import User from "@/modules/auth/models/User";
import Institution from "@/models/Institution";
import peopleRoutes from "@/modules/people/routes";

const routes = [
  ...authRoutes,
  ...teacherRoutes,
  ...coordinationRoutes,
  ...notificationsRoutes,
  ...settingsRoutes,
  ...reportsRoutes,
  ...botRoutes,
  ...peopleRoutes,
  ...schoolRoutes,
  {
    path: "/app",
    component: () => import("@/dashboard/index"),
    name: "app.main",
    meta: {
      title: "Educasis - Dashboard",
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory("educasis"),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = "Educasis";
  if (to.hasOwnProperty("meta")) {
    document.title = to.meta.title;
  }

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    const loginRoute = {
      name: "login",
      path: "/",
      query: { redirect: to.fullPath },
    };

    if (getToken()) {
      let user = User.query().first();

      if (user?.id) return next();
      else {
        User.me()
          .then(() => {
            const user = User.query().first();
            if (user?.$id) {
              Institution.getInstitution().then(() => next());
            }
          })
          .catch(() => next(loginRoute));
      }
    } else return next(loginRoute);
  } else return next();
});

export default router;
