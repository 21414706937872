<template>
  <li class="nav-item m-auto">
    <ul class="list-inline-group year-school-ul mb-0 pl-0">
      <label class="text-info year-school">Ano letivo</label
      >&nbsp;
      <li class="list-inline-item">
        <div class="form-group mb-0 amount-spent-select">
          <select
            v-model="yearStore.selectedYear"
            class="form-control form-control-sm form-select"
            @change="onChangeYear"
          >
            <template
              v-for="(year, index) in yearStore.yearOptions"
              :key="index"
            >
              <option :value="year.id">
                {{ year.text }}
              </option>
            </template>
          </select>
        </div>
      </li>
    </ul>
  </li>

  <!-- Notification -->
  <notification />
  <!-- /Notification -->

  <!-- User Menu -->
  <li class="nav-item nav-item-user">
    <a href="javascript:void(0);" class="dropdown-toggle nav-link">
      <span>
        {{ user.name }}
      </span>
    </a>
  </li>

  <li class="nav-item">
    <router-link to="/" class="nav-link text-danger" @click="logout">
      <i class="fas fa-power-off" style="font-size: 1rem" />
      Sair
    </router-link>
  </li>
  <!--  <li class="nav-item dropdown has-arrow">-->
  <!--    <a href="javascript:void(0);" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">-->
  <!--      <span>{{ user.name }} - <span class="text-muted mb-0">{{ user.role_name }}</span></span>-->
  <!--    </a>-->
  <!--    <div class="dropdown-menu">-->
  <!--      <div class="user-header">-->
  <!--        <div class="user-text">-->
  <!--          <span>{{ user.name }}</span>-->
  <!--          <p class="text-muted mb-0">{{ user.role_name }}</p>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <router-link class="dropdown-item" to="/profile">My Profile</router-link>-->
  <!--      <router-link class="dropdown-item" to="/inbox">Inbox</router-link>-->
  <!--      <router-link class="dropdown-item" @click="logout" to="/">Logout</router-link>-->
  <!--    </div>-->
  <!--  </li>-->
  <!-- /User Menu -->
</template>

<script>
import Auth from "@/modules/auth/models/Auth";
import User from "@/modules/auth/models/User";
import Notification from "@/components/notification";
import { useYearStore } from "../store/year";

export default {
  name: "UserMenu",
  components: {
    Notification,
  },
  data: () => ({
    user: User.query().first(),
    yearStore: useYearStore(),
  }),
  async mounted() {
    this.yearStore.getLastYears();
  },
  methods: {
    logout() {
      Auth.logout();
    },
    async onChangeYear({ target }) {
      await this.yearStore.setSelectedYear(target.value);
    },
  },
};
</script>
