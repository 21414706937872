<template>
  <!-- Header -->
  <div class="header">
    <logo />
    <toggle />

    <mobilemenu />

    <!-- Header Right Menu -->
    <ul class="nav user-menu">
      <!-- <notification /> -->

      <usermenu />
    </ul>
    <!-- /Header Right Menu -->
  </div>
  <!-- /Header -->
</template>

<script>
export default {
  mounted() {
    let $wrapper = $(".main-wrapper");
    let $body = $("body");
    let $sidebarOverlay = $(".sidebar-overlay");

    // Verifica se o elemento sidebar-overlay já existe antes de adicioná-lo
    if ($sidebarOverlay.length === 0) {
      $body.append('<div class="sidebar-overlay"></div>');
      $sidebarOverlay = $(".sidebar-overlay");
    }

    // Função para abrir o menu lateral
    function openSidebar() {
      $wrapper.addClass("slide-nav");
      $sidebarOverlay.addClass("opened");
      $("html").addClass("menu-opened");
      $("#task_window").removeClass("opened");
    }

    // Função para fechar o menu lateral
    function closeSidebar() {
      $wrapper.removeClass("slide-nav");
      $sidebarOverlay.removeClass("opened");
      $("html").removeClass("menu-opened");
    }

    // Evento para clicar no botão mobile
    $(document).on("click", "#mobile_btn", function() {
      if ($wrapper.hasClass("slide-nav")) {
        closeSidebar();
      } else {
        openSidebar();
      }
      return false;
    });

    // Evento para clicar fora do menu lateral
    $(document).on("click", function(e) {
      if (!$(e.target).closest(".sidebar").length) {
        closeSidebar();
      }
    });

    // Evento para clicar em um item de menu
    $(".sidebar-menu ul li.submenu a").click(function() {
      if ($(this).attr("href") !== "javascript:void(0);") {
        closeSidebar();
      }
    });

    // Evento para exibir menu ao passar o mouse
    $(document).on("mouseover", function(e) {
      e.stopPropagation();
      if (
        $("body").hasClass("mini-sidebar") &&
        $("#toggle_btn").is(":visible")
      ) {
        var targ = $(e.target).closest(".sidebar").length;
        if (targ) {
          $("body").addClass("expand-menu");
          $(".subdrop + ul").slideDown();
        } else {
          $("body").removeClass("expand-menu");
          $(".subdrop + ul").slideUp();
        }
        return false;
      }
    });

    // Evento para clicar em um item da barra de navegação
    // $(document).on("click", function(e) {
    //   if (
    //     !$(".top-nav-search").is(e.target) &&
    //     $(".top-nav-search").has(e.target).length === 0
    //   ) {
    //     $(".top-nav-search").removeClass("active");
    //   }
    // });
  },
};
</script>
