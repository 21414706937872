import { createApp } from "vue";
import { createPinia } from "pinia";
import VueMask from "@devindex/vue-mask";
import router from "./router";
import { store } from "@/store";
import moment from "moment";
import App from "./App.vue";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
/***********Layout **********/
import LayoutHeader from "./views/layouts/Header";
import Sidebar from "./views/layouts/Sidebar";
import Footer from "./views/layouts/Footer";
/***********Layout Components **********/
import Usermenu from "./components/usermenu.vue";
import Notification from "./components/notification.vue";
import Mobilemenu from "./components/mobilemenu.vue";
import Search from "./components/search.vue";
import Toggle from "./components/toggle.vue";
import Logo from "./components/logo.vue";
import VueSelect from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import PaginationComponent from "v-pagination-3";

/**********Importing plugins ***********/
import "./assets/plugins/bootstrap/css/bootstrap.min.css";
import "./assets/plugins/bootstrap/js/bootstrap.bundle.min.js";
import "./assets/plugins/fontawesome/css/fontawesome.min.css";
import "./assets/plugins/fontawesome/css/all.min.css";
import "./assets/css/bootstrap-datetimepicker.min.css";
import "./assets/css/bootstrap-datetimepicker.min.js";
import "v-calendar/dist/style.css";
import "./assets/css/style.css";
import "./assets/css/bootstrap4.min.css";
import "./assets/css/dataTables.bootstrap4.min.css";
import "./assets/css/jquery.dataTables.min.js";
import "./assets/css/dataTables.bootstrap4.min.js";
import "./assets/plugins/simple-calendar/simple-calendar.css";
import "./assets/css/feather.css";

import "moment";
import jquery from "jquery";
import Swal from "sweetalert2";

window.$ = jquery;
window.$swal = Swal;

const pinia = createPinia();
const app = createApp(App);

/* Global Components */
app.component("layout-header", LayoutHeader);
app.component("layout-sidebar", Sidebar);
app.component("layout-footer", Footer);
app.component("usermenu", Usermenu);
app.component("notification", Notification);
app.component("mobilemenu", Mobilemenu);
app.component("search", Search);
app.component("toggle", Toggle);
app.component("logo", Logo);
app.component("vue-select", VueSelect);
app.component("datepicker", DatePicker);
app.component("pagination-component", PaginationComponent);

app.use(VuePlyr); // Usando o Vue Plyr globalmente

app.use(router);
app.use(pinia);
app.use(VueMask);
app.use(store);

app.mount("#app");
