export default [
  {
    path: "/app/school",
    component: () => import("../views"),
    meta: {
      title: "Educasis - Escola",
      requiresAuth: true,
    },
    children: [
      /* Register */
      /* Students */
      {
        path: "register/student",
        name: "school.register.student",
        component: () => import("../register/students/views/List"),
      },
      {
        path: "register/student/create",
        name: "school.register.student.create",
        component: () => import("../register/students/views/Create"),
      },
      /* Institution */
      {
        path: "register/institution",
        name: "school.register.institution",
        component: () => import("../register/institution/views/List"),
      },
      {
        path: "register/institution/create",
        name: "school.register.institution.create",
        component: () => import("../register/institution/views/Create"),
      },
      {
        path: "register/institution/edit/:id",
        name: "school.register.institution.edit",
        component: () => import("../register/institution/views/Edit"),
      },
      /* PROCESS */
      {
        path: "process",
        name: "school.process.historic-school",
        component: () => import("../process/historic_school/views/List"),
      },
      {
        path: "process/show/student/:id",
        name: "school.process.historic-school.show",
        component: () => import("../process/historic_school/views/Show"),
      },
       /* Benefits */
      {
        path: "register/type/benefits",
        name: "school.register.type.benefits",
        component: () => import("../register/type/benefits/views/List"),
      },
      {
        path: "register/type/benefits/create",
        name: "school.register.type.benefits.create",
        component: () => import("../register/type/benefits/views/Create"),
      },
      {
        path: "register/type/benefits/edit/:id",
        name: "school.register.type.benefits.edit",
        component: () => import("../register/type/benefits/views/Edit"),
      },
      /* Curricular Components */
      {
        path: "curricular-components",
        name: "school.curricular-components",
        component: () => import("../curricular-components/views/List"),
      },
      {
        path: "curricular-components/create",
        name: "school.curricular-components.create",
        component: () => import("../curricular-components/views/Create"),
      },
      {
        path: "curricular-components/edit/:id",
        name: "school.curricular-components.edit",
        component: () => import("../curricular-components/views/Edit"),
      },

      /* Courses */
      {
        path: "courses",
        name: "school.courses",
        component: () => import("../courses/views/List"),
      },
      {
        path: "courses/create",
        name: "school.courses.create",
        component: () => import("../courses/views/Create"),
      },
      {
        path: "courses/edit/:id",
        name: "school.courses.edit",
        component: () => import("../courses/views/Edit"),
      },
      {
        path: "courses/show/:id",
        name: "school.courses.show",
        component: () => import("../courses/views/Show"),
      },

      /* Bncc */
      {
        path: "bncc",
        name: "school.bncc",
        component: () => import("../bncc/views/List.vue"),
      },
      {
        path: "bncc/edit/:id",
        name: "school.bncc.edit",
        component: () => import("../bncc/views/Edit.vue"),
      },
      {
        path: "bncc/add-many",
        name: "school.bncc.add-many",
        component: () => import("../bncc/views/AddMany.vue"),
      }
    ],
  },
];
